import { z } from "zod";

export const baseErrorsWording = {
  required: "Ce champ est requis",
  textMaxLength: (max: number) =>
    `Ce champ doit contenir au maximum ${max} caractères`,
  textMinLength: (min: number) =>
    `Ce champ doit contenir au moins ${min} caractères`,
};

const schemaCurrencyBase = z
  .union([
    z.number({ required_error: baseErrorsWording.required }),
    z.string({ required_error: baseErrorsWording.required }),
  ])
  .transform((value) => {
    const test = value.toString().replace(/\s/g, "");
    return test;
  });

const schemaCurrencyBasePipe = z.coerce.number({
  invalid_type_error: "Ce champ doit être un nombre",
  required_error: baseErrorsWording.required,
});

export const schemaCurrency = schemaCurrencyBase.pipe(schemaCurrencyBasePipe);

export const schemaCurrencyPositive = schemaCurrencyBase.pipe(
  schemaCurrencyBasePipe
    .min(0, "La valeur doit être positive")
    .max(2000000000, "La valeur est trop grande"),
);

export const schemaText = z
  .string({
    required_error: baseErrorsWording.required,
  })
  .trim()
  .min(1, { message: baseErrorsWording.textMinLength(1) })
  .max(64, { message: baseErrorsWording.textMaxLength(64) });

export const schemaTextArea = z
  .string({
    required_error: baseErrorsWording.required,
  })
  .trim()
  .min(5, { message: baseErrorsWording.textMinLength(5) })
  .max(1024, { message: baseErrorsWording.textMaxLength(1024) });

export const schemaAge = z.coerce
  .number({
    invalid_type_error: "Ce champ doit être un nombre",
    required_error: baseErrorsWording.required,
  })
  .min(18, "Vous devez être majeur pour utiliser ce service")
  .max(115, "Vous devez avoir moins de 115 ans pour utiliser ce service");

export const schemaSelect = z.union([z.string(), z.number()], {
  required_error: baseErrorsWording.required,
});

export const schemaRadio = z.string({
  required_error: baseErrorsWording.required,
});

export const schemaResponseFieldType = z.enum([
  "btn",
  "btn-wrap",
  "text",
  "textarea",
  "select",
  "address",
  "number",
  "currency",
  "currency-positive",
  "date",
  "percentage",
  "siret",
  "radio",
]);
