import { AssetType, AssetUsageType, CaseType, TaxType } from "@prisma/client";
import { ZodIssueCode, z } from "zod";
import { StringBoolean } from "~/types";
import {
  baseErrorsWording,
  schemaCurrency,
  schemaSelect,
  schemaText,
  schemaTextArea,
} from "../common/schemas";
import { type ChargeField } from "../dashboard/interfaces";
import { QuestionInlineSlug, QuestionSimpleSlug } from "./enums";
import { type OnboardingType } from "./interfaces";
import { isValidSiret } from "./utils/numberUtils";

export const schemaBtn = z.union([z.string(), z.number()], {
  required_error: baseErrorsWording.required,
});

export const schemaNumber = z.coerce
  .number({
    invalid_type_error: "Ce champ doit être un nombre",
    required_error: baseErrorsWording.required,
  })
  .min(0, "La valeur doit être positive")
  .max(2000000000, "La valeur est trop grande");

export const schemaSurface = z.coerce
  .number({
    invalid_type_error: "Ce champ doit être un nombre",
    required_error: baseErrorsWording.required,
  })
  .min(1, "La superficie du bien doit être d'au moins 1 m²")
  .max(2000000000, "La valeur est trop grande");

export const schemaAddress = z.object(
  {
    address: z.string({
      required_error: "Le format de l'adresse est invalide",
    }),
    zipcode: z.string({
      required_error: "Le format du code postal est invalide",
    }),
    city: z.string({ required_error: "Le format de la ville est invalide" }),
    country: z.string({ required_error: "Le format du pays est invalide" }),
  },
  { required_error: "Le format de cette adresse est invalide" },
);

export const schemaDate = z.coerce.date({
  errorMap: (issue, { defaultError }) => ({
    message:
      issue.code === ZodIssueCode.invalid_date
        ? baseErrorsWording.required
        : defaultError,
  }),
});

export const schemaEmail = z
  .string({
    required_error: baseErrorsWording.required,
  })
  .email({ message: "Ce champ doit être une adresse email valide." });

export const schemaPassword = z
  .string({
    required_error: baseErrorsWording.required,
  })
  .min(8, "Le mot de passe doit contenir au moins 8 caractères");

export const schemaPhone = z
  .string({
    required_error: baseErrorsWording.required,
  })
  .regex(/^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$/, {
    message: "Ce champ doit être un numéro de téléphone valide",
  });

// SIRET schemas
export const VALID_SIRET_LENGTH = 14;
export const schemaSiret = z.coerce
  .number({
    invalid_type_error: "Ce champ doit être un nombre",
    required_error: baseErrorsWording.required,
  })
  .refine((value) => value.toString().length === VALID_SIRET_LENGTH, {
    message: "Le numéro Siret doit contenir 14 caractères",
  })
  .refine(isValidSiret, {
    message: "Numéro Siret invalide",
  })
  .optional();

export const schemaSiretInput = z.object({
  siret: schemaSiret,
  companyName: z.string().optional(),
});

export const schemaSimpleAnswerValue = z.union([
  schemaText,
  schemaAddress,
  schemaTextArea,
  schemaSelect,
  schemaDate,
  schemaEmail,
  schemaPhone,
  schemaSiretInput,
]);

export const schemaAnswerNested = z.object({
  questionSlug: z.nativeEnum(QuestionInlineSlug),
  value: z.optional(schemaSimpleAnswerValue),
});

export const schemaRepeatableAnswer = z.array(schemaAnswerNested);

export const schemaAnswer = z.object({
  questionSlug: z.nativeEnum(QuestionSimpleSlug),
  value: z.union([schemaSimpleAnswerValue, schemaRepeatableAnswer]),
  branchIteration: z.number(),
  categoryIteration: z.number(),
  isEmpty: z.optional(z.boolean()),
});

export const schemaAnswers = z.array(schemaAnswer);

export const schemaContactForm = z.object({
  firstName: schemaText,
  lastName: schemaText,
  phone: schemaPhone,
});

export const schemaCredentials = z.object({
  email: schemaEmail,
  password: schemaPassword,
});

export const schemaToken = z.object({ token: z.string() });

export const schemaPasswordRecovery = z.object({ email: schemaEmail });

export const schemaPasswordReset = z.object({
  password: schemaPassword,
  passwordConfirmation: schemaPassword,
});
export type PasswordReset = z.infer<typeof schemaPasswordReset>;

export const schemaPasswordUpdate = schemaPasswordRecovery
  .merge(schemaPasswordReset)
  .merge(schemaToken)
  .refine((data) => data.password === data.passwordConfirmation, {
    message: "La confirmation du mot de passe ne correspond pas.",
    path: ["passwordConfirmation"],
  });

export type PasswordUpdate = z.infer<typeof schemaPasswordUpdate>;

export const schemaUserData = schemaContactForm.merge(schemaCredentials);

export const schemaQuestionType = z.enum([
  "btn",
  "btn-wrap",
  "text",
  "textarea",
  "select",
  "address",
  "number",
  "currency",
  "currency-positive",
  "date",
]);

export const schemaNavigation = z.object({
  branchSlug: z.string(),
  branchIteration: z.number(),
  categorySlug: z.string(),
  categoryIteration: z.number(),
  keepAnswers: z.optional(z.boolean()),
  exitPage: z.optional(z.enum(["company", "personal", "patrimonialSolo"])),
  redirect: z.optional(z.string()),
});

// Define the enum for onboarding types
export const OnboardingTypeEnum = z.enum([
  CaseType.standard,
  CaseType.patrimonial,
  CaseType.senior,
  CaseType.obo,
]); // Add more values for other onboarding types

// Define the schema for data in local storage based on OnboardingTypeEnum values
export const createGenericLocalStorageSchema = <T extends z.ZodTypeAny>(
  schemaItem: T,
) => {
  const shape = OnboardingTypeEnum.options.reduce(
    (acc, key) => {
      acc[key] = schemaItem;
      return acc;
    },
    {} as Record<OnboardingType, T>,
  );

  return z.object(shape);
};

export const schemaAnswersInLocalStorage =
  createGenericLocalStorageSchema(schemaAnswers);

export const assetUsageTypeSchema = z.enum([
  AssetUsageType.main_residence,
  AssetUsageType.secondary_residence,
  AssetUsageType.rental,
]);

export const assetTypeSchema = z.nativeEnum(AssetType);

export const taxTypeSchema = z.enum([TaxType.is, TaxType.ir]);

export const schemaBalanceSheetChargesForm = z.object({
  fieldType: z.custom<ChargeField>(),
  monthlyCharge: schemaCurrency.nullable(),
  rate: schemaNumber.optional().nullable(),
  endDate: schemaDate.optional(),
  assetId: z.string().optional().nullable(),
  isHypotec: z.nativeEnum(StringBoolean).optional(),
});

export type BalanceSheetChargesForm = z.infer<
  typeof schemaBalanceSheetChargesForm
>;
