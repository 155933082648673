"use client";
import { useRouter } from "next/navigation";
import { useCallback, type FC } from "react";
import { LuCircleSlash } from "react-icons/lu";
import { initialAnswers, initialNavigation } from "~/app/onboarding/atoms";
import { globalConfig } from "../../config";
import { useOnboardingAtoms } from "../../hooks/useOnboardingAtoms";
import { Text } from "../atoms/Text";
import { ButtonPrimary } from "../atoms/buttons/ButtonPrimary";

export const GlobalError: FC = () => {
  const { setAnswersForType, setNavigationForType } = useOnboardingAtoms();

  const router = useRouter();

  const resetStore = useCallback(() => {
    setAnswersForType(initialAnswers);
    setNavigationForType(initialNavigation);
  }, [setNavigationForType, setAnswersForType]);

  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center gap-6">
      <LuCircleSlash className="text-green-1 opacity-90" size={128} />
      <Text size="h2" className="text-green-1">
        Une erreur est survenue
      </Text>
      <Text>
        Nous avons été informés de cette erreur et la traiterons au plus vite
      </Text>
      <ButtonPrimary
        onClick={() => {
          resetStore();
          void router.push(globalConfig.websiteUrl);
        }}
      >
        {"Retour à l'accueil"}
      </ButtonPrimary>
    </div>
  );
};
