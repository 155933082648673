"use client";
import { type ReactNode, type FC } from "react";
import { type Session } from "next-auth";
import { SessionProvider } from "next-auth/react";

interface ClientAuthProviderProps {
  session: Session | null;
  children: ReactNode;
}

const ClientAuthProvider: FC<ClientAuthProviderProps> = ({
  session,
  children,
}) => <SessionProvider session={session}>{children}</SessionProvider>;

export default ClientAuthProvider;
