import { useAtom } from "jotai";
import { usePathname } from "next/navigation";
import { useMemo } from "react";
import {
  answersAtom,
  defaultNavigation,
  navigationAtom,
} from "~/app/onboarding/atoms";
import {
  type Answer,
  type Navigation,
  type OnboardingType,
} from "~/modules/onboarding/interfaces";
import { getOnboardingTypeByPath } from "~/modules/onboarding/utils/onboardingTypeManager";

// Be careful when using redirect as it may clear all answers
// Redirecting and keeping correct answers is not supported yet
export const useOnboardingAtoms = () => {
  const currentPathname = usePathname();
  const [answers, setAnswers] = useAtom(answersAtom);
  const [navigation, setNavigation] = useAtom(navigationAtom);

  const currentOnboardingType: OnboardingType = useMemo(() => {
    return getOnboardingTypeByPath(currentPathname);
  }, [currentPathname]);

  // Answers
  const getAnswersForType = (
    onboardingType = currentOnboardingType,
  ): Answer[] => answers[onboardingType];
  const setAnswersForType = (
    newAnswers: Answer[],
    onboardingType = currentOnboardingType,
  ) => setAnswers({ ...answers, [onboardingType]: newAnswers });

  // Navigation
  const getNavigation = (onboardingType = currentOnboardingType): Navigation =>
    navigation[onboardingType];
  const setNavigationForType = (
    newNavigation: Navigation,
    onboardingType = currentOnboardingType,
  ) => {
    setNavigation({
      ...navigation,
      [onboardingType]: newNavigation,
    });
  };

  const setUpdatedNavigation = (newNavigation: Partial<Navigation>) => {
    setNavigation({
      ...navigation,
      ...newNavigation,
    });
  };

  const setNavigationToDefault = () => {
    setNavigation(defaultNavigation);
  };

  return {
    setAnswers,
    getAnswersForType,
    setAnswersForType,
    getNavigation,
    setUpdatedNavigation,
    setNavigationForType,
    setNavigationToDefault,
    // Be careful when using this onboardingTypeByPathname, it may not be the one stored in the local storage
    onboardingTypeByPathname: currentOnboardingType,
  };
};
