import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/vercel/path0/node_modules/@highlight-run/next/dist/next-client.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Barlow\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"600\",\"700\"],\"variable\":\"--font-barlow\"}],\"variableName\":\"barlow\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Albert_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"variable\":\"--font-albertsans\"}],\"variableName\":\"albertSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["toast","success","warning","error","info"] */ "/vercel/path0/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/modules/common/components/atoms/ClientAuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/src/modules/common/components/atoms/Sonner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalError"] */ "/vercel/path0/src/modules/common/components/organisms/GlobalError.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/modules/common/components/utils/Highlight.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/modules/common/components/utils/VersionChecker.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCReactProvider"] */ "/vercel/path0/src/trpc/react.tsx");
