"use client";
import { HighlightInit } from "@highlight-run/next/client";
import { env } from "~/env";
import packageJson from "../../../../../package.json";

const Highlight = () => {
  return (
    <>
      {env.NEXT_PUBLIC_APP_ENV !== "local" && (
        <HighlightInit
          projectId={process.env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID}
          serviceName="nextjs-frontend"
          version={packageJson.version}
          tracingOrigins
          networkRecording={{
            enabled: true,
            recordHeadersAndBody: true,
            urlBlocklist: [],
          }}
          excludedHostnames={["localhost"]}
          environment={process.env.NEXT_PUBLIC_HIGHLIGHT_ENVIRONMENT}
        />
      )}
    </>
  );
};

export default Highlight;
